import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const TermsPage = () => (
    <Layout>
        <SEO title="Conditions générales d'utilisation"/>
        <div className={"container"}>
            <div className={"content"}>
                <div className={"title"}>
                    <h1>Conditions générales d'utilisation du site web VIVR</h1>
                </div>

                <p><strong>Dernière révision : 1er novembre 2020</strong></p>

                <h3><strong>Mots d’introduction :</strong></h3>
                <p>
                    Ce document a pour objet de définir les conditions d’utilisation applicables aux Utilisateurs et adhérents de la plateforme <strong>VIVR.CO</strong> . 
                    Après une brève introduction de la société et du Service proposé, 
                    ce document passe en revue les différents aspects qui couvrent l’utilisation de notre market place.
                    </p>

                <h2>1. Présentation de VIVR :</h2>
                    <p>
                        La société VIVR (ci-après VIVR) au capital de 1000 euros, dont le siège est situé au 22 rue de la fontaine au roi, 75011 PARIS 
                        et immatriculée au registre du commerce et des sociétés de Paris sous le n° 884 428 210  exploite un site internet qui a pour vocation de mettre en relation des particulier 
                        et des photographes professionnels.<br/>
                        Le site agit comme une marketplace (ci-après, plateforme) ayant pour but de faire coïncider une offre de prestations 
                        (ci-après, le Service) et une demande de prestation (ci-après, la Commande)

                    </p>




                <h2>2. Prise d’effet et durée</h2>
                    <p>
                        Les CGU s’appliquent lorsque survient un des évènements listés ci-dessous : <br/>

                        <point >(a) Consultation du site (pendant toute la durée de consultation)</point><br/>
                        <point >(b) Inscription à la plateforme (jusqu’à la désinscription)</point><br/>
                        <point >(c) Prise de commande (jusqu’à livraison & facturation de la prestation / annulation de la prestation)</point><br/>
                        
                    </p>
                    <p>
                        La plateforme s’octroie la possibilité de réviser à tout moment ces CGU, en contrepartie de quoi, 
                        elle s’engage à en tenir informé son public via sa page CGU et si besoin une communication dédiée.
                    </p>

                <h2>3. Le Service</h2>    
                        <h3>3.1 Description du Service</h3>
                            <p>
                                La plateforme offre un Service de réservation de séance photo à travers son site 
                                internet <a href="https://vivr.co">https://vivr.co</a>, permettant aux internautes de réserver une séance photo n'importe où en France, 
                                en sélectionnant un photographe parmi une liste de professionnels référencés.
                            </p>
                            <p>
                            A travers la plateforme, 3 options s’offre à l’Utilisateur :<br/>
                                <point>
                                    - Navigation simple
                                </point><br/>
                                <point>
                                    - Inscription compte Utilisateur
                                </point><br/>
                                <point>
                                    - Inscription compte professionnel
                                </point><br/>
                            </p>
                        <h3>3.2 Création du compte photographe</h3>
                            <p>
                                Lors de son inscription, le photographe optant pour l’ouverture d’un compte professionnel accepte de fournir 
                                à la plateforme l’ensemble des informations qui lui seront indiquées par envoi d’un courrier électronique à l’adresse mail qu’il aura indiquée.<br/>glo 
                                Parmi ses informations figurent, entre autres :<br/>
                                <point>
                                    - Etat civil
                                </point><br/>
                                <point>
                                    - Adresse de résidence (pour affecter son compte à une aire géographique)

                                </point><br/>
                                <point>
                                    - Numéro de Siret en cours de validité

                                </point><br/>
                                <point>
                                    - Dénomination sociale de sa société ou statut s’il est indépendant

                                </point><br/>
                                <point>
                                    - Référence du matériel utilisée

                                </point><br/>
                                <point>
                                    - Échantillon d’un book photo

                                </point><br/>

                            </p>
                            <p>
                                Aussi, afin de finaliser son inscription, l’Utilisateur devra indiquer un 
                                identifiant et un mot de passe afin que son compte puisse être activé.
                            </p>
                            <p>
                                La plateforme s’engage, pour sa part, à ne pas faire d’usage commercial 
                                de ses données et à en respecter la privacité. Ces informations ont vocation 
                                à permettre le meilleur référencement possible du professionnel en vue de garantir 
                                pour lui et pour la plateforme le plus d’opportunités d’affaires possibles.   
                            </p>
                        <h3>
                            3.3 Création du compte utlisateur
                        </h3>
                            <p>
                                Lors de sa navigation sur le site, l’Utilisateur a la possibilité de s’inscrire à la plateforme. Seule l’inscription permet d’accéder à l’ensemble des offres de Services de VIVR. 
                                Lors de son inscription l’Utilisateur devra renseigner une adresse mail valide et indiquer son identité.
                            </p>
                            <p>
                                Aussi, afin de finaliser son inscription, l’Utilisateur devra indiquer un identifiant et un mot de passe afin que son compte puisse être activé. 
                            </p>
                            <p>
                                La plateforme garantie à ses Utilisateurs le caractère privé des données qui lui sont communiquées.
                            </p>
                        <h3>
                            3.4 Suspension et résiliation de l’accès au Site et/ou du Service
                        </h3>
                            <p>
                                VIVR se réserve le droit de suspendre ou résilier l’accès à sa plateforme et/ou au Service à tout Bénéficiaire ne respectant pas tout ou partie des présentes CGU, sans préavis, 
                                et ce sans préjudice de tout recours qui pourrait être exercé à l’encontre du Bénéficiaire.
                            </p>
                            <p>
                                Par ailleurs, VIVR se réserve le droit de ne pas donner suite à une éventuelle nouvelle demande d’accès au Site ou de souscription au Service qui lui serait soumise par le Bénéficiaire.
                            </p>

                <h2>
                    4. Réservation d'une prestation:
                </h2>
                    <p>
                        L'Utilisateur s’engage ne passer Commande que par le biais du Site ; il s’interdit notamment tout ou partie du paiement 
                        d’une Prestation en contournant les modalités de règlement proposées par le Site ; il s’interdit également de contourner le Site pour réserver des Prestations.
                    </p>
                    <p>
                        Le Photographe s’engage à ne pas proposer à l’Utilisateur de réserver ses Prestations en dehors du Site, il s’interdit 
                        par ailleurs de percevoir tout ou partie du paiement en contournant les modalités de règlement proposées par le Site.
                    </p>
                    <p>
                        La Commande de prestation est réputée officielle au jour et heure du paiement par l'Utilisateur, 
                        qui exprime ainsi son accord sur l’Offre détaillée dans la Commande. 
                    </p>
                    <p>
                        Si un retard ou un problème lié à l’exécution de la Commande venait à survenir, l'Utilisateur s’engage à le signaler à VIVR par le biais de son Compte Utilisateur.
                    </p>
                    <p>
                        De même, il s’engage à confirmer la bonne exécution de la Commande par le biais de son Compte Utilisateur. En l’absence de signalement par l'Utilisateur d’un problème 
                        lié à sa Commande dans un délai de vingt et un (21) jours calendaires à compter du jour de la réalisation de la Prestation auquel s’est engagé le Photographe sur le Formulaire 
                        de réservation définitif (ci-après « Délai »), l'Utilisateur est réputé avoir reçu la Prestation réservée, l’avoir reçue sans retard, et être satisfait de la qualité de cette Prestation.
                    </p>
                <h2>
                    Fourniture de la prestation et conditions d’annulations et de remboursements
                </h2>
                    <p>
                        5.1. Le Photographe s'engage à fournir à l’Utilisateur une Prestation conforme au Formulaire de réservation définitif. 
                        La livraison des photographies sous format numérique ou sous tout autre format selon les termes convenus avec l'Utilisateur (ci-après « Livraison ») devra intervenir 
                        dans un délai qui inférieur ou égal à trois semaines à compter du jour de la réalisation de la Prestation.
                    </p>
                    <p>
                        5.2. Toute annulation par l'Utilisateur de la Prestation décrite dans le Formulaire de réservation définitif, en dehors du délai de rétractation visé à l’article 7 des présentes CGU et des cas de force majeure visés à 
                        l’article 11 des présentes CGU, donne lieu à la répartition du montant de la Commande de prestation dans les conditions suivantes :<br/>
                            <point>
                                -  Annulation jusqu'à 21 jours avant la date de la prestation : l'Utilisateur est remboursé à 100% du montant versé, déduit des frais d'annulation d'un montant de 25€ et des éventuels 
                                frais de Services au moment de la commande qui resteront intégralement dus à VIVR. 
                            </point><br/>
                            <point>
                                -  Annulation entre 21 jours et 8 jours avant la date de la prestation : <br/>
                                    <point>
                                        -  Dans le cas où le montant de la prestation est supérieur à 250€ : l'Utilisateur est remboursé à 100% du montant versé, déduit de l'acompte de 250€. 
                                        L'acompte de 250€ sera reversé au Photographe, déduit de la commission VIVR.  
                                    </point><br/>
                                    <point>
                                        - Dans les cas où le montant de la prestation est inférieur à 250€ : l'Utilisateur est remboursé à 50% du montant versé. 
                                        Les 50% restants sont reversés au Photographe, déduit d'un montant de 25€. 
                                    </point>
                            </point><br/>

                            <point>
                                - Dans les 7 jours précédents la date de la prestation : le montant versé par l'Utilisateur lors de la Commande est intégralement versé au Photographe, 
                                déduit de la commission VIVR ou d'un montant de 25€ si la commission VIVR est inférieure à 25€. 
                            </point>
                    </p>

                <h2>
                    6. Conditions financières
                </h2>
                    <p>
                        6.1 Les sommes versées par un Utilisateur au titre d’une Prestation réservée sont conservées notre prestataire de Service de paiement sur un compte dédié jusqu’à la confirmation de la part du client de la réalisation de la Prestation et de la réception des photos, ou jusqu’à l’écoulement du Délai. 
                    </p>
                    <p>
                        Une fois le Délai écoulé ou la confirmation reçue, VIVR procède, d’une part, à la remise au Photographe du paiement de l’Utilisateur déduction faite de la commission liée à l’offre de Service faite au prestataire (ci-après rémunération). 
                        Ainsi le photographe reçoit un paiement net = paiement Utilisateur – commission VIVR. 
                    </p>
                    <p>
                        Ce montant lui est versé par virement aux coordonnées bancaires qu’il aura préalable indiqué dans son compte professionnel.
                    </p>
                    <p>
                        Dans le cas où l'Utilisateur ne confirme pas la réception des photos via le site, le Délai est considéré comme écoulé au bout de 21 jours après la confirmation de la part du Photographe, depuis le site, qu'il a livré les photos à l'Utilisateur.  Le règlement du photographe est alors effectué par le VIVR. 
                    </p>
                    <p>
                        6.2 La rémunération de VIVR (« Commission ») correspond à une commission sur le montant total du prix de la Prestation réservée. 
                    </p>
                    <p>
                        Cette commission correspond à 6% H.T du prix qui est supportée par le Photographe Professionnel (« Commission due par le Photographe Professionnel ») ou 12% H.T du prix qui est supportée par le Photographe Non-Professionnel (« Commission due par le Photographe Non-Professionnel »).
                    </p>
                <h2>
                    7. Droit de rétraction
                </h2>
                    <p>
                        7.1 En application de l’article L.121-21 du Code de la consommation, tout consommateur (c'est-à-dire toute personne physique qui agit à des fins qui n'entrent pas dans le cadre de son activité commerciale, industrielle, artisanale ou libérale) dispose d’un délai de quatorze (14) jours pour exercer son droit de rétractation à l’égard d’un contrat conclu à distance. 
                    </p>
                    <p>
                        Les Utilisateurs, en tant que consommateurs, bénéficient donc de ce droit de rétractation.
                    </p>
                    <p>
                        7.2 L'Utilisateur n’a pas à justifier de motif pour exercer son droit de rétractation, l'Utilisateur doit adresser via le Site, dans un délai maximum de 14 jours 
                        à compter du jour de la conclusion de la Commande, le formulaire de rétractation disponible sur le bon de commande ou sur les Sites à l’adresse électronique suivante <a href="mailto:contact@vivr.co"> contact@vivr.co</a> .
                    </p>
                    <p>
                        Il peut également exercer ce droit par une déclaration, dénuée d’ambiguïté, exprimant sa volonté de se rétracter. 
                    </p>
                    <p>
                        L'Utilisateur consommateur sera alors remboursé de la totalité des sommes versées, sans retard injustifié et au plus tard dans les quatorze (14) jours à compter de la date à laquelle VIVR reçoit l’avis de rétractation.
                    </p>
                    <p>
                        7.3 Pour toute Commande de prestation, l'Utilisateur qui a expressément accepté de bénéficier de la Prestation avant la fin du délai de rétractation renonce expressément à la faculté d’exercer ce droit. Par conséquent, s’il se rétracte de la Commande ultérieurement, 
                        l'Utilisateur devra payer des frais au Photographe et/ou à VIVR, selon les modalités définies à l’article 5.2 des présentes CGU.
                    </p>
                <h2>
                    8. Responsabilités
                </h2>
                    <p>
                        VIVR décline toute responsabilité en cas d’inexécution, d’annulation ou de retard dans la fourniture des Prestations. 
                    </p>
                    <p>
                        Dans l’hypothèse où, toutefois, sa responsabilité serait engagée à ce titre, elle s’engage uniquement à rembourser 
                        l’Utilisateur selon les modalités prévues à l’article 5 des présentes CGU.
                    </p>
                <h2>
                    9. Convention de preuve
                </h2>
                    <p>
                        Toute communication écrite entre un Photographe et un Utilisateur 
                        ainsi qu’entre VIVR et à la fois le Photographe et l'Utilisateur enregistré la Messagerie fait foi et peut à ce titre être utilisé comme preuve en cas de litige.
                    </p>
                <h2>
                    10. Données àç caractère personnel
                </h2>
                    <p>
                        Conformément à l’article 32 de la loi nº 78-17 du 6 janvier 1978 modifiée en 2004 (dite « loi Informatique et Libertés »), 
                        les Bénéficiaires sont informés que des données à caractère personnel les concernant font l’objet d’un traitement automatisé par VIVR afin d’assurer la Commande de Prestation et de permettre l’accès aux Services en ligne. 
                        Ces données pourront notamment faire l’objet d’analyses statistiques de la part de VIVR.
                    </p>
                    <p>
                        Les informations demandées dans le cadre des formulaires de DEVIS disponibles sur le Site marquées d’un astérisque (*) sont obligatoires et nécessaires à la gestion des demandes des Bénéficiaires, 
                        notamment les Commandes de prestation. Le défaut de réponse dans un champ obligatoire entraînera l'impossibilité pour VIVR de traiter les demandes des Bénéficiaires. 
                    </p>
                    <p>
                        Conformément aux dispositions de la loi « Informatique et Libertés » (articles 38 et suivants), les Bénéficiaires disposent d’un droit d’accès, de rectification et de suppression des données les concernant et peuvent 
                        s’opposer au traitement pour des motifs légitimes. Pour exercer ces droits, il convient de s’adresser à <a href="mailto:contact@vivr.co">contact@vivr.co</a> . Les Bénéficiaires sont informés que la suppression de certaines données du fait de l’exercice de 
                        leur droit de suppression ou d’opposition peut leur rendre inaccessible tout ou partie du Site ou du Service.
                    </p>
                <h2>
                    11. Force majeure
                </h2>
                    <p>
                       Les Parties ne peuvent être tenues pour responsables de l’inexécution de leurs obligations prévues aux CGU, 
                       si cette inexécution est due à un cas de force majeure. Par force majeure, il faut entendre tout événement imprévisible, 
                       insurmontable et provenant d’une cause extérieure aux Parties.  
                    </p>
                <h2>
                    12. Obligations légales du Photographe
                </h2>
                    <p>
                        La relation contractuelle entre le Photographe et l'Utilisateur doit se faire dans le respect des lois en vigueur, et notamment du Code de la consommation, qui encadre les obligations légales auxquelles le Photographe est soumis lorsqu’il contracte avec un Utilisateur consommateur. 
                        Le Photographe est informé qu’il doit respecter, notamment mais pas uniquement, les obligations en lien avec les informations précontractuelles et au délai de rétractation. 
                    </p>
                <h2>
                    13. Litiges
                </h2>
                    <p>
                        EN CAS DE LITIGE(S) DECOULANT DE LA VALIDITE, DE L’INTERPRETATION, DE L’EXECUTION OU DE L’INEXECUTION, DE L’INTERRUPTION OU DE LA RESILIATION DES PRESENTES CGU : 
                    </p>
                    <p>
                        CONFORMEMENT A LA PROCEDURE DES ARTICLES L152-1 ET SUIVANTS DU CODE DE LA CONSOMMATION, EN CAS DE DIFFEREND ENTRE UN UTILISATEUR ET VIVR, ET APRES AVOIR TENTE DE REGLER LE LITIGE A L’AMIABLE AVEC VIVR, L’UTILISATEUR PEUT PORTER LE LITIGE DEVANT UN MEDIATEUR DE LA CONSOMMATION SUR LE SITE DE RESOLUTION DES LITIGES ENTRE CONSOMMATEURS ET E-COMMERÇANTS DE LA COMMISSION EUROPEENNE. 
                    </p>
                    <p>
                        DANS LES AUTRES CAS, LE(S) LITIGE(S) SERONT SOUMIS PREALABLEMENT A TOUT RECOURS CONTENTIEUX A LA MEDIATION CONFORMEMENT AU REGLEMENT DE MEDIATION DU CMAP – CENTRE DE MEDIATION ET D’ARBITRAGE DE PARIS – PRES LA CHAMBRE DE COMMERCE ET D’INDUSTRIE DE PARIS ILE-DE-FRANCE, DONT LES PARTIES DECLARENT AVOIR EU CONNAISSANCE ET AUQUEL ELLES DECLARENT ADHERER. 
                    </p>
                    <p>
                        SI LES PARTIES AU(X) LITIGE(S) SONT DES COMMERÇANTS, EN CAS DE PERSISTANCE DU LITIGE POSTERIEUREMENT A UNE MESURE DE MEDIATION, LE TRIBUNAL DE COMMERCE DE PARIS SERA SEUL COMPETENT. 
                    </p>

                      


                
            </div>
        </div>
    </Layout>
)

export default TermsPage
